// CandidatePage.js

import React from "react";
import "./CandidateStyle.css"; // Import the CSS file for styling
import { Card, Button } from "react-bootstrap";
import DeleteButton from "./DeleteButtonComponent";

import { formatRelative } from "date-fns";
import { ar } from "date-fns/locale";

const containerURL = "https://hoeapplications.blob.core.windows.net/uploads/";

const CandidatePage = (props) => {
  // Extracting profile data from props.location.state.data or assigning empty object
  const {
    id,
    age,
    createdAt,
    dateOfBirth,
    dateOfNationalIdEnd,
    educationLevel,
    email,
    emptyType,
    englishProficiency,
    gender,
    haveHealthProblems,
    healthProblems,
    major,
    nameArabic,
    nameEnglish,
    nationalId,
    nationality,
    phoneNumber,
    previousWork,
    region,
    source,
    workName,
    workedPreviously,
    yearsOfExperience,
    tiktokUrl,
    instaUrl,
    linkedinUrl,
    cityName,
    otherLangauge,
    // files
    cv,
    personalImage,
    videoURL,
    personlViedoURL,
  } = props.location.state.data || {};

  const createdAtReadable = formatRelative(new Date(createdAt), new Date(), {
    locale: ar,
  });
  
  console.log(createdAtReadable);
  return (
    <Card
      className="user-details-card px-0"
      style={{
        borderTop: "none",
      }}
    >
      <Card.Body className="card-body">
        {/* User information */}
        <div className="user-info" style={{ border: "none" }}>
          <Button
            variant="primary"
            onClick={() => props.history.goBack()}
            className="mb-3"
            style={{ display: "flex", alignItems: "center" }}
          >
            <i className="ri-arrow-right-line fw-bold me-1"></i> العودة
          </Button>
          <h2
            className="info-title"
            style={{
              fontSize: "2.0rem",
              color: "#000",
            }}
          >
            تفاصيل التقديم
          </h2>
          <div className="row">
            <p className="info-item col-md-6">
              <span className="info-label">الاسم الانجليزي:</span>{" "}
              <span>{nameEnglish}</span>
            </p>
            <p className="info-item col-md-6">
              <span className="info-label">الاسم العربي:</span>{" "}
              <span>{nameArabic}</span>
            </p>
            <p className="info-item col-md-6">
              <span className="info-label">الجنس:</span> <span>{gender}</span>
            </p>
            <p className="info-item col-md-6">
              <span className="info-label">الجنسية:</span>
              <span>{nationality}</span>
            </p>
            <p className="info-item col-md-6">
              <span className="info-label">الهوية:</span>{" "}
              <span>{nationalId}</span>
            </p>
            <p className="info-item col-md-6">
              <span className="info-label">انتهاء الهوية:</span>{" "}
              <span>{dateOfNationalIdEnd.split("T")[0]}</span>
            </p>
            <p className="info-item col-md-6">
              <span className="info-label">تاريخ الميلاد:</span>{" "}
              <span>{dateOfBirth.split("T")[0]}</span>
            </p>
            <p className="info-item col-md-6">
              <span className="info-label">رقم الهاتف:</span>{" "}
              <span>{phoneNumber}</span>
            </p>
            <p className="info-item col-md-6">
              <span className="info-label">الايميل:</span> <span>{email}</span>
            </p>
            <p className="info-item col-md-6">
              <span className="info-label">المنطقة:</span> <span>{region}</span>
            </p>
            {cityName && (
              <p className="info-item col-md-6">
                <span className="info-label">المدينة:</span>{" "}
                <span>{cityName}</span>
              </p>
            )}
            <p className="info-item col-md-6">
              <span className="info-label">المستوى التعليمي:</span>{" "}
              <span>{educationLevel}</span>
            </p>
            <p className="info-item col-md-6">
              <span className="info-label">التخصص:</span> <span>{major}</span>
            </p>
            {workedPreviously && (
              <>
                <p className="info-item col-md-6">
                  <span className="info-label">سنوات الخبرة:</span>
                  <span>{yearsOfExperience}</span>
                </p>
                <p className="info-item col-md-6">
                  <span className="info-label">الخبرة السابقة:</span>{" "}
                  <span>{previousWork}</span>
                </p>
              </>
            )}
            <p className="info-item col-md-6">
              <span className="info-label">المصدر:</span> <span>{source}</span>
            </p>
            <p className="info-item col-md-6">
              <span className="info-label">مستوى اللغة الانجليزية:</span>{" "}
              <span>{englishProficiency}</span>
            </p>
            {otherLangauge && (
              <p className="info-item col-md-6">
                <span className="info-label">اللغات الاخرى</span>{" "}
                <span>{otherLangauge}</span>
              </p>
            )}
            <p className="info-item col-md-6">
              <span className="info-label">العمر:</span>
              <span>{age}</span>
            </p>
            {haveHealthProblems && (
              <p className="info-item col-md-6">
                <span className="info-label">الامراض:</span>
                <span>{healthProblems}</span>
              </p>
            )}
            {/* <p className="info-item col-md-6"><span className="info-label">تواصل اجتماعي:</span> <a
            href={socialMedia}
            target='_blank'
            rel="noopener noreferrer"
          >
            اضغط هنا
          </a></p> */}
            {tiktokUrl && (
              <p className="info-item col-md-6">
                <span className="info-label">تك توك</span>{" "}
                <a href={tiktokUrl} target="_blank" rel="noopener noreferrer">
                  اضغط هنا
                </a>
              </p>
            )}
            {instaUrl && (
              <p className="info-item col-md-6">
                <span className="info-label">انستغرام</span>{" "}
                <a href={instaUrl} target="_blank" rel="noopener noreferrer">
                  اضغط هنا
                </a>
              </p>
            )}
            {linkedinUrl && (
              <p className="info-item col-md-6">
                <span className="info-label">لينكد ان</span>{" "}
                <a href={linkedinUrl} target="_blank" rel="noopener noreferrer">
                  اضغط هنا
                </a>
              </p>
            )}
            <p className="info-item col-md-6">
              <span className="info-label">التفرغ:</span>{" "}
              <span>{emptyType}</span>
            </p>
            <p className="info-item col-md-6">
              <span className="info-label">المسمى الوظيفي:</span>{" "}
              <span>{workName}</span>
            </p>
            <p className="info-item col-md-6">
              <span className="info-label">تاريخ التسجيل:</span>{" "}
              <span>{createdAtReadable}</span>
            </p>
          </div>
          {cv && (
            <Button
              variant="primary"
              href={`${containerURL}${cv}`}
              target="_blank"
              rel="noopener noreferrer"
              className="w-100 mb-3"
            >
              السيرة الذاتية
            </Button>
          )}
          {/* Video display */}
          {videoURL && (
            <div className="video-container">
              <div>الفيديو الخاص بالمهارة</div>
              <video controls className="video">
                <source src={`${containerURL}${videoURL}`} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
          {/* Personal image display */}
          {personalImage && (
            <div className="image-container">
              <div>الصورة الشخصية</div>
              <img
                src={`${containerURL}${personalImage}`}
                alt="الصورة الشخصية"
                className="image"
              />
            </div>
          )}
          {/* Personal video display */}
          {personlViedoURL && (
            <div className="video-container">
              <div>الفيديو التعريفي</div>
              <video controls className="video">
                <source
                  src={`${containerURL}${personlViedoURL}`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
          <DeleteButton id={id}></DeleteButton>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CandidatePage;
