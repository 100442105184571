import React, { Component } from "react";
import {
    Container,
    Button,
    Spinner,
    Row,
    Col,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//Import Components
import { FiltersCards } from "./FiltersCards";
import { ApplicationsTable } from "./ApplicationsTable";

import * as XLSX from 'xlsx';

// import post from '../../helpers/apiPost';
import { get, API_URL } from '../../helpers/api_helper';

const containerURL = "https://hoeapplications.blob.core.windows.net/uploads/";

class Candidates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrumbItems: [
                { title: "Forms", link: "#" },
                { title: "Dashboard", link: "#" },
            ],
            filters: {
                gender: '',
                isSaudi: [],
                ageMin: '',
                ageMax: '',
                region: [],
                educationLevel: [],
                workedPreviously: '',
                experienceMin: '',
                experienceMax: '',
                englishProficiencyMin: '',
                englishProficiencyMax: '',
                createdAtMin: '',
                createdAtMax: '',
                nationalId: '',
            },
            isLoading: false,
        };

    }

    // create a function that set filters
    setFilters = (filters) => {
        console.log(filters);
        this.setState({ filters });
    }

    exportData = () => {
        this.setState({ isLoading: true });
        // include the filters object in the request query
        const url = `/dashboard/filtered_applications?filters=${JSON.stringify(this.state.filters)}`;
        // Send a request to the server with the this.state.filters object
        get(url)
            .then((response) => {

                // Get the applications array from the response
                const applications = response.applications;

                applications.forEach((application) => {
                    if(application.videoURL)
                        application.videoURL = containerURL + application.videoURL;
                    if(application.cv)
                        application.cv = containerURL + application.cv;
                    if(application.personalImage)
                        application.personalImage = containerURL + application.personalImage;
                    if(application.personlViedoURL)
                        application.personlViedoURL = containerURL + application.personlViedoURL;
                });

                console.log(applications[0]);

                // Create a worksheet
                const ws = XLSX.utils.json_to_sheet(applications);

                // Create a workbook with the worksheet
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

                // Export the workbook as an Excel file
                XLSX.writeFile(wb, 'exported_data.xlsx');

                this.setState({ isLoading: false });
            }).catch((error) => {
                console.log(error);
                alert('حدث خطأ أثناء تصدير البيانات');
                this.setState({ isLoading: false });
            });
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Candidates" breadcrumbItems={this.state.breadcrumbItems} />
                        <FiltersCards
                            filters={this.state.filters}
                            isLoading={this.state.isLoading}
                            exportData={this.exportData}
                            setFilters={this.setFilters}
                        />
                        <Row>
                            <Col xs={6}>
                                <h3>
                                    المتقدمين
                                </h3>
                            </Col>
                            <Col xs={6} className="d-flex justify-content-end align-items-center">
                                <Button
                                    onClick={this.exportData}
                                    color="primary"
                                >
                                    {this.state.isLoading ? (
                                        <>
                                            جاري التصدير... <Spinner size="sm" color="light" />
                                        </>
                                    ) : (
                                        <>
                                            تصدير إلى Excel <i className="mdi mdi-microsoft-excel"></i>
                                        </>
                                    )}
                                </Button>
                            </Col>
                        </Row>
                        <ApplicationsTable
                            filters={this.state.filters}
                        />
                    </Container>

                </div>
            </React.Fragment>
        );
    };
};

export default Candidates;
