import { all } from 'redux-saga/effects'

//public
// import accountSaga from './auth/register/saga';
import loginSaga from './auth/login/saga';
// import layout saga
import layoutSaga from './layout/saga';

export default function* rootSaga() {
    yield all([

        //public
        loginSaga(),
        layoutSaga(),
    ])
}