import React from "react";
import { Route, Redirect } from "react-router-dom";

const AppRoute = ({
	component: Component,
	layout: Layout,
	isAuthProtected,
	...rest
}) => (
		<Route
			{...rest}
			render={props => {

				if (isAuthProtected && !isTokenValid()) {
					return (
						<Redirect to={{ pathname: "/login", state: { from: props.location } }} />
					);
				}

				return (
					<Layout>
						<Component {...props} />
					</Layout>
				);
			}}
		/>
	);

// a function that checks if the authUser.expiry is greater than the current time
// if it is, then return true
// else return false
function isTokenValid() {
	const authUser = JSON.parse(localStorage.getItem("authUser"));
	const currentTime = new Date().getTime();
	if (authUser && authUser.expiry > currentTime) {
		return true;
	} else {
		if (authUser) {
			localStorage.removeItem("authUser");
		}
		return false;
	}
}


export default AppRoute;

