import React, { useState } from "react";
import { del } from "../../helpers/api_helper";
import { useHistory } from "react-router-dom"; // Import useHistory from React Router

const DeleteButton = ({ id }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const history = useHistory(); // Initialize history

  const handleDelete = async () => {
    try {
      const response = await del(`/dashboard/delete-by-db-id/${id}`);
      console.log(response.data);
      // Navigate back to the previous page upon successful deletion
      history.goBack();
    } catch (error) {
      console.error("Error deleting application:", error);
    }
  };

  const handleConfirmation = () => {
    setShowConfirmation(true);
  };

  const handleCancel = () => {
    setShowConfirmation(false);
  };

  return (
    <div className="mt-3">
      <div>
        <button className="btn btn-danger w-100" onClick={handleConfirmation}>
          حذف الشخص
        </button>
        {showConfirmation && (
          <div
            className="modal centered"
            tabIndex="-1"
            role="dialog"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              zIndex: 1050, // Ensuring modal is above most other elements
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backdropFilter: "blur(5px)",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                zIndex: -1, // Behind the modal content
              }}
            ></div>
            <div
              className="modal-dialog"
              role="document"
              style={{
                position: "relative",
                zIndex: 2, // Above the blur effect
              }}
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">تأكيد الحذف</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={handleCancel}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body px-5 mx-5">
                  <p className="fw-bold">هل تريد تأكيد عملية الحذف؟</p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleCancel}
                  >
                    لا
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleDelete}
                  >
                    نعم
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DeleteButton;
