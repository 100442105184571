import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";


// Dashboard
import Dashboard from "../pages/Dashboard/index";
import Candidates from "../pages/Candidates/index";
import CandidatePage from "../pages/Candidates/CandidatePage";

const authProtectedRoutes = [
	{ path: "/dashboard", component: Dashboard },

	{ path: "/candidates", component: Candidates },
	
	{ path: "/candidate", component: CandidatePage },
	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	// { path: "/forgot-password", component: ForgetPwd },
	// { path: "/register", component: Register },
	// { path: "/lock-screen", component: AuthLockScreen },
];

export { authProtectedRoutes, publicRoutes };
